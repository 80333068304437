<template>
  <div class="container">
    <search-top @search="handleTopSearch"></search-top>
    <div class="wapper flex w1200">
      <left-menu :key="leftKey" @leftsearch="handleLeftSearch" :leftdata="leftData"></left-menu>
      <div class="rightPage">
        <div
          class="screen"
          v-if="
            screeningData != undefined &&
            (screeningData.subjectIds != undefined || screeningData.clcIds != undefined || screeningData.publishYear != undefined || screeningData['title,author'] != undefined || screeningData['title'] != undefined || screeningData['author'] != undefined || screeningData['isbn'] != undefined)
          "
        >
          <div style="display: inline-block">
            <span class="bold">筛选条件</span>
            <template v-if="screeningData.subjectIds || screeningData.clcIds || screeningData.publishYear || screeningData['title,author'] != undefined || screeningData['title'] != undefined || screeningData['author'] != undefined || screeningData['isbn'] != undefined"> > </template>
          </div>
          <div v-if="screeningData.subjectIds" class="flex1">
            图书分类：<span>{{ screeningData.subjectIds }}</span
            ><i @click="handleClose('subjectIds')" class="el-icon-close"></i>
          </div>
          <div v-if="screeningData.clcIds" class="flex1">
            中图法分类：<span>{{ screeningData.clcIds }}</span
            ><i @click="handleClose('clcIds')" class="el-icon-close"></i>
          </div>
          <div v-if="screeningData.publishYear" class="flex1">
            出版年：<span>{{ screeningData.publishYear }}</span
            ><i @click="handleClose('publishYear')" class="el-icon-close"></i>
          </div>
          <div v-if="screeningData['title,author']" class="flex1">
            全部：<span>{{ screeningData["title,author"] }}</span
            ><i @click="handleClose('title,author')" class="el-icon-close"></i>
          </div>
          <div v-if="screeningData['title']" class="flex1">
            书名：<span>{{ screeningData["title"] }}</span
            ><i @click="handleClose('title')" class="el-icon-close"></i>
          </div>
          <div v-if="screeningData['author']" class="flex1">
            作者：<span>{{ screeningData["author"] }}</span
            ><i @click="handleClose('author')" class="el-icon-close"></i>
          </div>
          <div v-if="screeningData['isbn']" class="flex1">
            ISBN：<span>{{ screeningData["isbn"] }}</span
            ><i @click="handleClose('isbn')" class="el-icon-close"></i>
          </div>
        </div>
        <div class="sort flex" style="position: relative">
          <span class="totalspan"
            >共<span class="redtotal">{{ total }}</span
            >条记录</span
          >
          <div style="position: absolute; left: 160px">
            <span style="width: 110px; font-weight: bold; line-height: 58px" class="w150">出版时间：</span>
            <el-date-picker class="yearcontent" v-model="queryForm.publishYearMonthStart" style="width: 130px" type="month" placeholder="开始时间" format="yyyy-MM" value-format="yyyy-MM"> </el-date-picker>
            <div style="width: 10px; height: 2px; background: #999999; display: inline-block; margin-left: 13px; margin-right: 13px; vertical-align: middle"></div>
            <el-date-picker class="yearcontent" format="yyyy-MM" value-format="yyyy-MM" v-model="queryForm.publishYearMontEnd" style="width: 130px" type="month" placeholder="结束时间"> </el-date-picker>
          </div>
          <div class="sort-search flex">
            <el-input maxlength="30" clearable v-model="queryForm.searchBarWord" @keyup.enter.native="toSearch" placeholder="请输入要在结果中检索的内容"></el-input>
            <img @click="toSearch" src="../../assets/images/icon-search-inside.png" />
          </div>
        </div>
        <template v-if="total > 0">
          <div class="total flex">
            <order :key="sortKey" :default-item="activedItem" @click="handleSort"></order>
            <p style="user-select: none">
              <span
                @click="handleChangePage(-1)"
                :style="{
                  cursor: queryForm.pageNum <= 1 ? 'not-allowed' : 'pointer',
                }"
                class="fanye"
                >&lt;</span
              ><i> {{ Math.ceil(total / 10) > 0 ? queryForm.pageNum : 0 }} </i>/
              {{ Math.ceil(total / 10) }}
              <span
                class="fanye"
                @click="handleChangePage(1)"
                :style="{
                  cursor: queryForm.pageNum == Math.ceil(total / 10) ? 'not-allowed' : 'pointer',
                }"
                >&gt;</span
              >
            </p>
          </div>
          <div class="list" v-loading="loading">
            <ul>
              <li class="flex" v-for="(item, index) in bookData" :key="index">
                <div @click="toDetail(item.id)" class="img">
                  <img :src="getCover(item.cover)" />
                </div>
                <div class="right_info">
                  <p :title="handleConvertRedTitle(item.title)" class="title mb15" @click="toDetail(item.id)" v-html="item.title"></p>
                  <div class="right-info-bottom">
                    <p v-if="item.author">作者：<span v-html="item.author"></span></p>
                    <p v-if="item.isbn">ISBN：<span v-html="item.isbn"></span></p>
                    <p v-if="item.publishDate || item.publisher">
                      <span v-if="item.publisher" class="mr15">出版单位：<span v-html="item.publisher"></span></span>
                      <span v-if="item.publishDate">出版日期：<span v-html="item.publishDate"></span></span>
                    </p>
                    <p v-if="item.content" class="nrjj">内容简介：{{ item.content }}</p>
                  </div>
                </div>
              </li>
            </ul>
            <el-pagination v-if="total > 0" background @current-change="handleCurrentChange" :page-size="queryForm.pageSize" :current-page="queryForm.pageNum" layout="prev, pager, next, jumper" :total="total"> </el-pagination>
          </div>
        </template>
        <template v-else>
          <div style="padding-top: 100px; text-align: center">
            <img src="@/assets/images/zanwu2.png" />
            <p class="nodata">抱歉，没有找到相关内容！</p>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import test from "./test";
import SearchTop from "@/components/searchTop";
import LeftMenu from "@/components/leftmenu";
import { searchDataList, searchLeft, getDetail } from "@/api/home";
import jiangxu from "@/assets/images/icon-jiangxu-default.png";
import jiangxued from "@/assets/images/icon-jiangxu-select.png";
import shengxu from "@/assets/images/icon-shengxu-default.png";
import shengxued from "@/assets/images/icon-shengxu-select.png";
import baseUrl from "@/api/baseurl.js";
import zanwu from "@/assets/images/zanwu.png";
import order from "@/components/order.vue";

export default {
  data() {
    return {
      sortKey: 1,
      zanwu,
      baseUrl: baseUrl.baseUrl,
      leftKey: 1,
      sortType: "score",
      jiangxuSrc: jiangxued,
      shengxuSrc: shengxu,
      jiangxu,
      jiangxued,
      shengxu,
      shengxued,
      pagesize: 10,
      pageno: 1,
      total: 100,
      leftData: [],
      bookData: [],
      queryForm: {
        searchWord: "", //检索词
        searchBarWord: "", //检索条-检索词 字段默认为题名、作者、ISBN
        searchField: "title,author", //检索字段，先固定值
        subjectIds: [], //左侧学科
        clcIds: [], //左侧中图法
        publishYear: null, //出版年
        pageNum: 1, //页码 1
        pageSize: 10, //页面大小 10
        orderByColumn: "state_time", //排序字段 默认出版日期
        isAsc: "desc", //排序方式 默降序排序
        publishYearStart: null,
        publishYearEnd: null,
        publishYearMonthStart: "",
        publishYearMontEnd: "",
      },
      screeningData: {
        id: 1,
      },
      activedItem: {
        id: "state_time",
        asc: false,
        desc: false,
        name: "上架时间",
        value: "desc",
      },
      loading: false,
    };
  },
  components: {
    SearchTop,
    LeftMenu,
    test,
    order,
  },
  created() {
    this.queryForm = this.$store.state.searchParam;
    this.screeningData = this.$store.state.searchParam.searchNames;
    if (this.queryForm.searchWord != "") {
      this.screeningData[this.queryForm.searchField] = this.queryForm.searchWord;
    }
    if (!this.isEmptyObject(this.screeningData)) {
      this.activedItem = {
        id: "score",
        asc: false,
        desc: false,
        name: "相关度",
        value: "desc",
      };
    }
    delete this.queryForm.searchNames;
    this.handleAllSearch();
  },
  mounted() {},
  methods: {
    handleConvertRedTitle(val) {
      return val.split("<font style='color:red'>").join("").split("</font>").join("");
    },
    getCover(id) {
      if (id != undefined && id != null && id != "") {
        return this.baseUrl + id;
      } else {
        return zanwu;
      }
    },
    // 查看详情
    toDetail(id) {
      const _url = this.$router.resolve({ path: "/detail", query: { id } });
      window.open(_url.href, "_blank");
    },
    childByVal(val) {
      console.log(val);
    },
    // 排序
    handleSort(item) {
      this.queryForm.orderByColumn = item.id;
      this.sortType = item.id;
      this.queryForm.isAsc = item.value;
      this.queryForm.pageNum = 1;
      this.getBookData();
    },
    isEmptyObject(obj) {
      return Object.keys(obj).length === 0;
    },
    // 叉掉筛选条件
    handleClose(type) {
      this.$bus.$emit("close", type);
      delete this.screeningData[type];
      if (type == "publishYear") {
        this.queryForm[type] = null;
        delete this.screeningData[type];
      } else if (type == "subjectIds") {
        this.queryForm[type] = [];
        delete this.screeningData[type];
      } else if (type == "clcIds") {
        this.queryForm[type] = [];
        delete this.screeningData[type];
      } else if (type == "title,author") {
        this.queryForm.searchWord = "";
        delete this.screeningData[type];
      } else if (type == "title") {
        this.queryForm.searchWord = "";
        delete this.screeningData[type];
      } else if (type == "author") {
        this.queryForm.searchWord = "";
        delete this.screeningData[type];
      } else if (type == "isbn") {
        this.queryForm.searchWord = "";
        delete this.screeningData[type];
      }
      this.$store.commit("TOP_ARRAY", []); //清除top组件 选中效果
      this.queryForm.searchBarWord = "";
      this.queryForm.publishYearStart = null;
      this.queryForm.publishYearEnd = null;
      this.queryForm.publishYearMonthStart = null;
      this.queryForm.publishYearMonthEnd = null;
      this.queryForm.pageNum = 1;
      this.jiangxuSrc = jiangxued;
      this.shengxuSrc = shengxu;
      this.queryForm.orderByColumn = "state_time";
      this.queryForm.isAsc = "desc";
      this.sortType = "score";

      if (this.isEmptyObject(this.screeningData)) {
        //没有检索词了
        this.activedItem = {
          id: "state_time",
          asc: false,
          desc: false,
          name: "上架时间",
          value: "desc",
        };
      }

      this.handleAllSearch();
    },
    // 筛选条件拼装
    handleScreeningData() {
      if (this.queryForm.subjectIds.length > 0) {
        this.screeningData.subjectIds = {
          name: "学科分类",
          value: "",
        };
      }
      if (this.queryForm.clcIds.length > 0) {
      }
      if (this.queryForm.publishYear) {
      }
    },
    handleAllSearch() {
      this.getLeftData();
      this.getBookData();
    },
    // 头部检索框检索
    handleTopSearch(value) {
      this.queryForm.searchWord = value;
      this.queryForm.searchBarWord = "";
      this.queryForm.publishYearStart = null;
      this.queryForm.publishYearEnd = null;
      this.queryForm.publishYearMonthStart = null;
      this.queryForm.publishYearMonthEnd = null;
      this.leftKey++;
      this.queryForm.subjectIds = [];
      this.queryForm.clcIds = [];
      this.queryForm.publishYear = null;
      this.queryForm.pageNum = 1;
      this.jiangxuSrc = jiangxued;
      this.shengxuSrc = shengxu;
      this.queryForm.orderByColumn = "score"; //排序字段 默认相关度降序
      this.queryForm.isAsc = "desc";
      this.screeningData = {};
      this.sortType = "score";
      this.sortKey++;

      // 只有检索词不为空时，才按照相关度
      if (this.queryForm.searchWord != "" && this.queryForm.searchWord != null) {
        // 检索时默认排序方式，相关度降序
        this.activedItem = {
          id: "score",
          asc: false,
          desc: false,
          name: "相关度",
          value: "desc",
        };
      }

      //将高级检索放进top展示回显
      this.screeningData[this.queryForm.searchField] = this.queryForm.searchWord;
      this.handleAllSearch();
    },

    // 二级检索
    toSearch() {
      this.activedItem = {
        id: "score",
        asc: false,
        desc: false,
        name: "相关度",
        value: "desc",
      };
      this.queryForm.pageNum = 1;
      this.getBookData();
    },
    // 左侧聚合检索
    handleLeftSearch(type, value, names) {
      if (type == "clear") {
        delete this.screeningData.clcIds;
        delete this.screeningData.subjectIds;
        this.queryForm.clcIds = [];
        this.queryForm.subjectIds = [];
      } else if (type == "publishYear") {
        this.screeningData[type] = names;
        this.queryForm[type] = value.id;
      } else {
        this.screeningData[type] = names;
        this.queryForm[type] = [value.id];
      }
      this.queryForm.searchBarWord = "";
      this.queryForm.publishYearStart = null;
      this.queryForm.publishYearEnd = null;
      this.queryForm.publishYearMonthStart = null;
      this.queryForm.publishYearMonthEnd = null;
      this.queryForm.pageNum = 1;
      this.jiangxuSrc = jiangxued;
      this.shengxuSrc = shengxu;
      this.queryForm.orderByColumn = "state_time";
      this.queryForm.isAsc = "desc";
      this.sortType = "score";
      this.sortKey++;
      this.getLeftData();
      this.getBookData();
    },
    handleChangePage(val) {
      if ((this.queryForm.pageNum != 1 && val == -1) || (val == 1 && this.queryForm.pageNum != Math.ceil(this.total / 10))) {
        this.queryForm.pageNum += val;
        this.getBookData();
      }
    },
    // 获取左侧聚合数据
    getLeftData() {
      // let postBody = this.$store.state.searchParam
      let param = JSON.parse(JSON.stringify(this.queryForm));
      // param.subjectIds = [];
      searchLeft(param).then(res => {
        this.leftData = res.data;
      });
    },
    // 获取书目列表数据
    getBookData() {
      this.loading = true;
      searchDataList(this.queryForm).then(res => {
        this.bookData = res.rows;
        this.total = res.total;
        this.loading = false;
        document.body.scrollTop = document.documentElement.scrollTop = 0;
      });
    },
    handleCurrentChange(val) {
      this.queryForm.pageNum = val;
      this.getBookData();
    },
  },
};
</script>

<style lang="scss" scoped>
.yearcontent ::v-deep .el-input__inner {
  border: 1px solid transparent;
}

.totalspan {
  font-size: 16px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
  text-align: left;
  color: #666666;
  line-height: 36px;

  .redtotal {
    margin-left: 5px;
    margin-right: 5px;
    color: #ff0000;
  }
}

.nodata {
  font-size: 16px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
  text-align: center;
  color: #333333;
}

.pxfs {
  &:hover {
    color: #078bef;
  }
}

.pxfs_actived {
  color: #078bef;
}

.container {
  width: 100%;
  background: #fff;
  min-height: calc(100vh - 185px);

  .nrjj {
    font-size: 16px;
    color: #666;
    font-family: "microsoft yahei";
    word-break: break-all;
    // width: 690px;
    line-height: 28px;
    position: relative;
    // max-height: 40px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .fanye {
    user-select: none;
    cursor: pointer;
    cursor: not-allowed;

    &:hover {
      color: #078bef;
    }
  }

  .wapper {
    margin-top: 30px;
    align-items: flex-start;
    padding-bottom: 50px;

    .rightPage {
      margin-left: 20px;
      flex: 1;
      min-width: 0;

      .bold {
        font-weight: bold;
      }

      .screen {
        border: 1px solid #e6e6e6;
        padding: 11.5px 30px 1.5px;
        align-items: center;
        margin-bottom: 10px;
        display: block;

        .flex1 {
          color: #666666;
          background: #f7f7f7;
          padding: 6.5px 10px;
          align-items: center;
          margin-left: 5px;
          margin-bottom: 10px;
          display: inline-block;

          span {
            color: #078bef;
          }

          i {
            margin-left: 10px;
            cursor: pointer;
          }
        }
      }

      .sort {
        // margin-top: 10px;
        background: #f7f7f7;
        padding: 9px 30px;
        align-items: center;

        .pointer {
          img {
            margin-left: 5px;
            vertical-align: -2px;
          }
        }

        .sort-search {
          margin-left: auto;
          background: #fff;
          align-items: center;

          .el-input {
            width: 270px;

            &::after {
              content: "";
              position: absolute;
              right: 0;
              width: 1px;
              height: 20px;
              top: 50%;
              margin-top: -10px;
              background: #ccc;
            }
          }

          ::v-deep.el-input__inner {
            border: none;
          }

          img {
            margin: 0 20px;
            width: 18px;
            height: 18px;
            cursor: pointer;
          }
        }
      }

      .total {
        border-bottom: 1px solid #e6e6e6;
        padding: 20px 0 10px;
        color: #666666;
        line-height: 24px;

        > span {
          line-height: 24px;
          color: #ff0000;
        }

        p {
          margin-left: auto;

          i {
            font-style: normal;
            color: #ff0000;
          }
        }
      }

      .list {
        ul {
          li {
            border-bottom: 1px dashed #dedede;
            padding: 20px 30px;

            .img {
              width: 180px;
              min-height: 180px;
              display: flex;
              align-items: center;
              justify-content: center;
              overflow: hidden;
              border: 1px solid #dedede;
              cursor: pointer;
              background: #fff;

              img {
                max-width: 178px;
                max-height: 178px;
              }
            }

            .right_info {
              flex: 1;
              color: #666;
              margin-left: 20px;
              min-width: 0;

              .title {
                color: #333;
                font-size: 20px;
                cursor: pointer;
                font-family: Microsoft YaHei, Microsoft YaHei-Regular;
                white-space: nowrap;
                width: 650px;
                overflow: hidden;
                text-overflow: ellipsis;
              }

              .right-info-bottom {
                p {
                  // margin-bottom: 5px;
                  font-size: 16px;
                  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
                  font-weight: 400;
                  text-align: left;
                  color: #666666;
                  line-height: 28px;
                }
              }
            }

            &:hover {
              background: #f7f7f7;

              .right_info {
                .title {
                  color: #078bef;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
