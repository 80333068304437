<template>
  <div>
    <h1 @click="$emit('test','hellow')">hello world</h1>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>